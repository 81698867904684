<template>
  <div class="home-page">
    <div class="home-content">
      <div class="img-row">
        <img class="home-img" src="@/assets/home/home.png" alt="home" />
      </div>
      <div class="welcome-row">
        <span>你好</span>
        <span class="user-name-text">{{ user.fullName }}</span>
        <span>!</span>
      </div>
      <div class="welcome-row welcome-s-row">
        <span>欢迎访问</span>
      </div>
      <div class="welcome-row">
        <span class="sys-name-text">Resource Management System</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "home",
  setup() {
    const store = useStore();
    const user = computed(() => store.state.app.user);

    return {
      user,
    };
  },
});
</script>
<style lang="less" scoped>
.home-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .home-content {
    margin-bottom: 150px;
  }

  .img-row {
    display: flex;
    justify-content: center;
    margin-bottom: 33px;

    .home-img {
      width: 538px;
    }
  }

  .welcome-row {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    text-align: center;
    color: #3182ce;

    .user-name-text {
      padding: 0 10px;
    }
  }

  .welcome-s-row {
    font-size: 24px;
    line-height: 150%;
    color: rgba(49, 130, 206, 0.5);
    margin: 12px 0 4px;
  }
}
</style>
